.header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.search-container {
  position: relative;
  padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 500px;
}

.search-hamburgericon-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: none;
  height: 100%;
  width: auto;
}

.avatar-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  flex-grow: 1;
}

.search-input {
  position: absolute;
  padding: 4px;
  padding-left: 40px;
  border: 1px solid var(--border-grey);
  border-radius: 0.75rem;
  color: var(--color-grey);
  outline: none;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  color: var(--font-color-primary);
}

.search-input:focus {
  box-shadow: 0px 1px 4px 0px rgba(99, 114, 130, 0.5);
  border: none;
}

.search-icon {
  position: absolute;
  color: var(--font-color-primary);
  left: 15px;
  z-index: 2;
  cursor: pointer;
}

.search-close-icon {
  position: absolute;
  color: var(--color-grey);
  right: 15px;
  z-index: 2;
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.search-close-icon:hover {
  background: var(--color-brown);
  border-radius: 4px;
}

@media screen and (max-width: 767px) {
  .search-hamburgericon-container {
    display: flex;
  }
  .search-container {
    flex-grow: 1;
    max-width: 400px;
  }
  .avatar-container {
    flex-grow: none;
  }
}
