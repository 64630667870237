.uparrow-icon {
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-left: 5px;
}

.clicked {
  color: var(--color-grey);
}

.table-row-container {
  display: grid;
  grid-template-columns: auto 100px 100px 150px 50px;
  grid-template-rows: auto;
  width: 100%;
}
.table-row-container:hover {
  background: #e8e8e8;
}

.checkbox-selected {
  background: #deebff;
}

@media screen and (max-width: 767px) {
  .table-row-container {
    grid-template-columns: auto;
    grid-template-rows: auto;
  }
}

@media screen and (max-width: 1024px) {
  .table-row-container {
    grid-template-columns: 2fr 150px;
  }
  .hide-column {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .table-row-container {
    grid-template-columns: 1fr;
  }
}
