.forgot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
}
.forgot-container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.forgot-submit-container {
  width: 350px;
  height: auto;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 4.75rem;
  /* box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1); */
}

.title {
  font-size: 1.75rem;
  font-weight: 400;
  padding-top: 1rem;
  padding-bottom: 1.75rem;
  text-align: left;
  width: 100%;
}

.forgot-p {
  width: 100%;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 1.5rem;
  color: var(--font-color-primary);
}

.forgot-input-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 0.25rem;
  margin-bottom: 2rem;
}

.forgot-label {
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
  color: var(--color-grey);
}

.forgot-input {
  width: 100%;
  padding: 1rem;
  outline: none;
  border: 1px solid var(--border-grey);
  height: 50px;
  margin-top: 0.5rem;
}

.forgot-input:focus {
  outline: 3px solid var(--border-blue);
}

.forgot-input:hover {
  border: 1px solid black;
}

.not-valid {
  border: 1px solid var(--color-red);
}

.focus-input:not(:hover):focus {
  border: 1px solid black;
}

.forgot-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--color-blue);
  height: 50px;
  border-radius: 1rem;
  transition: background 300ms ease-in-out;
  color: whitesmoke;
  font-weight: 600;
}

.forgot-btn:hover {
  background: var(--color-blue-secondary);
}

.forgot-btn.loading {
  background: grey;
}

.gif-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50px;
}

.error-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.error-title {
  font-weight: 600;
  width: 100%;
  color: red;
  font-size: 1.25rem;
}

.password-validator-container {
  width: 100%;
  list-style-type: none;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.password-validator-container li {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  color: var(--font-color-primary);
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
}

.valid {
  color: var(--color-green) !important;
}

.password-dont-match {
  width: 100%;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  color: var(--color-red);
  text-align: left;
  margin-top: 0.5rem;
}

.password-match {
  width: 100%;
  font-size: 10px;
  line-height: 15px;
  font-weight: 400;
  color: var(--color-green);
  text-align: left;
  margin-top: 0.5rem;
}
