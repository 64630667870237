select {
  appearance: none;
  outline: none;
  cursor: pointer;
  font-family: serif;
  color: #938e88;
  text-align: center;
}
select:hover {
  background: #f5efe5;
}

option {
  border: 1px solid #d1d1d1;
}

.photo-container {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.img-preview {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* @media screen and (max-width: 767px) {
  .img-preview {
    max-width: 90%;
    max-height: 90%;
  }
}

@media screen and (min-width: 1200px) {
  .img-preview {
    max-width: 80%;
    max-height: 600px;
  }
} */
