.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 50px;
}

.sso-input {
  border: 1px solid var(--border-grey);
  outline: none;
}

.sso-input:hover {
  border: 1px solid black;
}

.sso-input:focus {
  outline: 3px solid var(--border-blue);
}

.sso-label {
  font-size: 0.9rem;
  color: var(--color-grey);
}

.sso-btn {
  border: none;
  color: whitesmoke;
  background: var(--color-blue);
  font-weight: 500;
  min-width: 150px;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  height: 40px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sso-btn.btn-disable {
  background: grey;
}

.sso-btn:hover {
  background: var(--color-blue-secondary);
}

.sso-disable-btn {
  border: none;
  color: whitesmoke;
  background: var(--color-red);
  font-weight: 500;
  min-width: 150px;
  position: absolute;
  bottom: 1rem;
  right: 12rem;
  height: 40px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sso-disable-btn:hover {
  background: var(--color-red-secondary);
}

textarea {
  height: 80px;
}

.sso-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.sso-box {
  width: 600px;
  height: 350px;
  background: white;
  border-radius: 1rem;
  transform: translateY(0%);
}
