.twofa-box {
  width: 600px;
  height: 300px;
  background: white;
  border-radius: 1rem;
  transform: translateY(0%);
}

.step-one {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.step-one-title {
  width: 100%;
  font-size: 1.5rem;
  text-align: left;
  padding: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: 400;
}

.step-one-p {
  text-align: left;
  font-size: 0.9rem;
  color: var(--color-grey);
  font-weight: 400;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
}

.step-one-close {
  position: absolute;
  right: 0.75rem;
  top: 1rem;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.step-one-close:hover {
  background: var(--color-brown);
  border-radius: 0.25rem;
}

.step-one-button {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  height: 40px;
  min-width: 70px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  position: absolute;
  top: 75%;
  right: 1.5rem;
}

.step-one-button:hover {
  background: var(--color-black);
}

.step-4-back-button {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-brown);
  height: 40px;
  min-width: 70px;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  color: black;
}

.step-4-back-button:hover {
  background: var(--color-brown-secondary);
}

.step-two-input {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  width: 250px;
  height: 40px;
}

.step-two-input:focus {
  outline: 3px solid var(--color-blue);
}

.step-two-forgotpassword {
  text-decoration: none;
  position: absolute;
  top: 78%;
  left: 1.5rem;
  color: var(--color-blue-dark);
  font-weight: 500;
}

.step-two-forgotpassword:hover {
  text-decoration: underline;
}

.step-two-error {
  width: 200px;
  height: 30px;
  position: absolute;
  left: 1.5rem;
  top: 42%;
  text-align: left;
  color: var(--color-red-lite);
  font-size: 0.75rem;
  font-weight: 500;
}

.step-three-radio-container {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  font-size: 0.8rem;
}

.step-three-radio {
  width: 50%;
  height: 100%;
  border: 1px solid var(--border-light-blue);
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.step-three-radio-input {
  height: 100%;
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-three-radio-label-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  width: 85%;
  height: 100%;
  padding-top: 1rem;
}

label {
  width: 100%;
  text-align: left;
}

.step-three-radio-label {
  font-weight: 600;
  font-size: 1rem;
}

.step-three-radio-text {
  font-weight: 400;
  color: var(--color-grey);
}

.step-three-radio-active {
  background: var(--color-light-blue-third);
}

.step-four-ul {
  width: 100%;
  padding-left: 3rem;
  padding-right: 1.5rem;
  color: var(--color-grey);
  font-size: 0.8rem;
  list-style-type: disc;
  line-height: 1.5rem;
}
.step-four-li {
  width: 100%;
  text-align: left;
}

.step-four-button-container {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5rem;
  gap: 1rem;
}

.step-6-input {
  outline: none;
  border: 1px solid var(--color-grey);
  padding-left: 1.5rem;
  height: 40px;
  width: 150px;
  float: left;
}

.step-6-input:hover {
  border: 1px solid black;
}

.step-6-input:focus {
  outline: 3px solid var(--color-blue);
}

.step-6-input-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.step-five-error {
  width: 100%;
  height: 30px;
  text-align: left;
  color: var(--color-red-lite);
  font-size: 0.75rem;
  font-weight: 500;
  padding-left: 1.5rem;
}
