.itemdetails {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 300px;
  border: 1px solid #d3d3d3;
}

@media (max-width: 767px) {
  .itemdetails {
    position: absolute;
    overflow: auto;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    border: 1px solid #e5e7eb;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background: #f9f9f9;
  }
}
