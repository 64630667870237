.twofa-info-box {
  background: var(--color-brown-dark);
  width: 100%;
  min-height: 200px;
  border: 1px solid var(--color-grey-third);
}

.border-bottom-none {
  border-bottom: none;
}

.padding-right {
  padding-right: 0.5rem;
}

.twofa-info-p {
  width: 100%;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 400;
  color: var(--color-grey-secondary);
}

.twofa-info-action-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 100%;
}

.twofa-info-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.twofa-info-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  gap: 1rem;
}

.twofa-info-row-border-bottom {
  border-bottom: 1px solid var(--border-grey);
}

.twofa-info-label {
  color: var(--font-color-primary);
  font-size: 1rem;
  width: auto;
  font-weight: 400;
  text-align: left;
  width: 100%;
}

.twofa-info-row-context-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
}

.sso-input-font {
  font-size: 0.9rem;
  font-weight: 300;
}

.sso-loading {
  width: 100%;
  height: 100%;
  min-width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
