.custom-shadow {
  box-shadow: rgba(99, 114, 130, 0.5) 0px 1px 4px 0px;
}

.cancellation-banner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
  background: #ffe6e6;
  border: 1px solid var(--color-red);
  padding: 0.5rem;
  margin-top: 1rem;
}

.cancellation-p {
  color: #716b61;
  text-align: left;
  font-weight: 500;
  font-size: 0.9rem;
}

.cancellation-link {
  color: var(--color-blue);
}
.cancellation-link:hover {
  color: var(--color-red);
}
