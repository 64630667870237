.upload-input-box {
  width: 100%;
  height: 100%;
  pointer-events: all;
  opacity: 0;
  position: relative;
  cursor: pointer;
  z-index: 10;
}

.avatar-preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.avatar-preview-editor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 0.5rem;
}

.avatar-preview-editor button {
  height: 100%;
  min-width: 75px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
}

.photo-done {
  background: black;
  color: whitesmoke;
}

.photo-done:hover {
  background: var(--color-black);
}

.photo-change {
  position: relative;
  font-weight: 600;
  font-size: 1rem;
  color: black;
  outline: 0;
  overflow: hidden;
  z-index: 1;
  cursor: pointer;
  border: 1px solid var(--border-grey);
}
.photo-change::before {
  content: "";
  position: absolute;
  background: var(--color-brown);
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  transition: top 50ms ease-in;
}

.photo-change:hover::before {
  top: 0;
}

.avatar-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  z-index: 11;
  border-radius: 50%;
  box-shadow: 0px 0px 5px var(--border-grey);
}

.avatar-upload.invalid-image {
  border: 1px dashed var(--color-red);
}

.invalid-image.avatar-upload::before {
  background: var(--color-red-secondary);
}

.avatar-upload:not(.invalid-image):hover::before {
  background: var(--color-light-blue);
}

.avatar-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.avatar-thumbnail-skeleton {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
