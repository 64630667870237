.password-input {
  height: 40px;
  width: 100%;
  padding: 4px;
  border: 1px solid var(--border-grey);
  outline: none;
}

.password-input:focus {
  outline: 3px solid var(--border-blue);
}

.password-input:hover {
  border: 1px solid black;
}
.password-input:not(:hover):focus:not(.error) {
  border: 1px solid var(--border-grey);
}
.error.password-input:not(:hover):focus {
  border: 1px solid var(--color-red);
}

.error {
  border: 1px solid var(--color-red);
}

.flex-relative {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  flex-direction: row;
  height: 40px;
}
