@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --border-grey: #dbdbdb;
  --font-color-primary: #1a1918;
  --border-blue: #428bff;
  --border-light-blue: #b2cdff;
  --color-blue: #0061fee0;
  --color-blue-secondary: #004dc7;
  --color-light-blue: #d1e1ff;
  --color-light-blue-secondary: #edf4ff;
  --color-light-blue-third: #deebff;
  --color-blue-dark: #1b72fe;
  --color-brown: #ebe0cc;
  --color-brown-lite: #f5efe5;
  --color-brown-secondary: #e3d3b7;
  --color-grey: #808080;
  --color-grey-secondary: #736c64;
  --color-smoke-white: #f7f5f2;
  --color-green: #267b5e;
  --color-greyed: #bbb5ae;
  --color-red: #b50032;
  --color-red-secondary: #f81266;
  --color-red-lite: #f06e63;
  --color-brown-dark: #fbf4ea;
  --color-black: #393633;
  --color-grey-third: #bbb5ae;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
