.twoFA_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.twoFA_header {
  width: 100%;
}

.flex_row_width_full_grow {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.twoFA_input_container {
  width: 100%;
  display: flex;
  height: 70px;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}
.twoFA_code_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 70%;
}
.twoFA_code_button_container {
  display: flex;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  width: 30%;
}

.twoFA_title {
  text-align: left;
  font-size: 1.25rem;
  color: var(--font-color-primary);
  /* color: #716b61; */
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.twoFA_subheading {
  color: var(--color-grey);
  font-size: 0.75rem;
  margin: 0;
  width: 100%;
  text-align: left;
  margin-bottom: 1.5rem;
}

.twoFA_code_label {
  width: 100%;
  font-size: 1rem;
  padding-bottom: 0.25rem;
  color: var(--color-grey);
  text-align: left;
  height: 40%;
}

.twoFA_error {
  color: var(--color-red);
  font-size: 0.75rem;
  margin: 0;
  width: 100%;
  text-align: left;
}

.twoFA_box {
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  min-width: 400px;
  height: auto;
  box-shadow: rgba(99, 114, 130, 0.5) 0px 1px 4px 0px;
}

.twoFA_input {
  outline: none;
  border: 1px solid var(--color-grey);
  height: 60%;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
  appearance: none;
}
.twoFA_input:hover {
  border: 1px solid black;
}

.twoFA_input_error {
  border: 1px solid var(--color-red);
}

.twoFA_input:focus {
  outline: 3px solid var(--color-blue-dark);
}

.twoFA_input[type="number"]::-webkit-outer-spin-button,
.twoFA_input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.twoFA_button {
  width: 100%;
  height: 60%;
  background: var(--color-blue);
  font-weight: 500;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0%;
  left: 0%;
  color: whitesmoke;
}

.twoFA_button:not(.btn_disabled):hover {
  background: var(--color-blue-secondary);
}

.btn_disabled {
  background: var(--color-grey-third);
}

@media screen and (max-width: 767px) {
  .twoFA_box {
    width: 80%;
  }
}
