.cancelButton {
  position: relative;
  font-weight: 600;
  font-size: 1rem;
  color: #808080;
  outline: 0;
  overflow: hidden;
  background: #f5efe5;
  letter-spacing: 0.02rem;
  z-index: 1;
  cursor: pointer;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.fill-grey:hover {
  color: #808080;
}

.fill-grey:before {
  content: "";
  position: absolute;
  background: #ece1ce;
  bottom: 0;
  left: 0;
  right: 0;
  top: 100%;
  z-index: -1;
  -webkit-transition: top 0.09s ease-in;
}

.fill-grey:hover:before {
  top: 0;
}
