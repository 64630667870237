.gallery-image {
  /* width: 82px;
  height: 82px; */
  height: 100%;
  width: 100%;
  object-fit: contain;
  /* border: 1px solid #e5e7eb; */
  border: 1px solid var(--border-grey);
}

.btn-active {
  background: var(--color-brown);
}

.btn-active:hover {
  background: var(--color-brown-secondary);
}

.round-button {
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 50%;
}

.round-button:hover {
  background: #fbf4ea;
}
