.menu-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  box-sizing: border-box;
  z-index: 1000;
  border: none;
  scale: 0;
  transform-origin: top right;
  transition: scale 200ms ease-in-out;
}

.openMenu {
  scale: 1;
  border: 1px solid #cccccc;
}
