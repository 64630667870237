.panel-container-mobile {
  position: absolute;
  z-index: 1000;
  inset: 60px 0px 0px 0px;
  height: auto;
  display: flex;
  justify-content: flex-start;
  border: none;
  align-items: center;
  width: 100%;
  visibility: hidden;
  background: rgba(237, 224, 224, 0.5);
  pointer-events: none;
}

.panel-container-mobile.panel-grow {
  visibility: visible;
  pointer-events: all;
}

.panel-container-mobile.panel-hide {
  visibility: hidden;
  pointer-events: none;
}

.delay-close {
  visibility: visible;
  pointer-events: all;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  gap: 0;
  width: 100vw;
  height: 100vh;
}

.dashboard-body-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
}

.dashboard-search-container {
  width: 100%;
  height: 60px;
  padding-top: 16px;
  margin-bottom: 8px;
}

.panel-container {
  width: 240px;
  height: 100vh;
}

.open {
  background: rgba(0, 0, 0, 0.4);
  visibility: visible;
  transition: background 300ms ease-in-out;
}

.collapse {
  visibility: visible;
  background: rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 767px) {
  .panel-container {
    display: none;
  }
  .dashboard-body-container {
    flex-grow: 1;
  }
}

@media screen and (min-width: 768px) {
  .open {
    visibility: hidden;
  }
}
